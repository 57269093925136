import React, { Component } from 'react';
import { Chart } from 'primereact/chart';

export default class AtualizacaoLattes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            DataLattes: null,
            stackedData: {},
            stackedOptions: {}
        };
    }

    componentDidMount() {
        this.CarregarDados();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.QualInstituicao !== this.props.QualInstituicao) {
            this.CarregarDados();
        }
    }

    CarregarDados() {
        this.setState({ loading: true });
        let StringFetch = 'api/Pesquisadores/UltimaAtualizacaoLattes?QualInstituicao=' + this.props.QualInstituicao;        
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ DataLattes: data, loading: false });
                this.ConfiguraDados();
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    mapDataToChart() {
        let VetorCores = ["#FF6384", "#36A2EB", "#FFCE56", "#ff0000", "#ff00bf", "#8000ff", "#0040ff", "#00ffff"];
        let QuaisLabels = [];
        let QuaisData = [];
        let QuaisCores = [];
        let a = this.state.DataLattes;
        for (let i = 0; i < a.length; i++) {
            QuaisLabels.push(a[i].value);
            QuaisData.push(a[i].text);
            QuaisCores.push(VetorCores[i]);
        }

        let stackedDataUpdated = {
            labels: QuaisLabels,
            datasets: [
                {
                    data: QuaisData,
                    backgroundColor: QuaisCores,
                    hoverBackgroundColor: QuaisCores
                }]
        };

        let stackedOptionsUpdated = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            maintainAspectRatio: false
        };

        this.setState({
            stackedData: stackedDataUpdated,
            stackedOptions: stackedOptionsUpdated
        });

    }
       
    ConfiguraDados() {
        this.mapDataToChart = this.mapDataToChart.bind(this);       
        this.mapDataToChart();
    }

    renderAtualizacaoLattes() {
        return (
            <div>
                <div className="content-section implementation">
                    <Chart height="250px" type="pie" data={this.state.stackedData} options={this.state.stackedOptions}  />
                </div>
            </div>
        );
    }

    render() {
        const { loading, error } = this.state;

        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents = <div className="overlay">
                           <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                       </div>;
        }
        else {
            contents = this.renderAtualizacaoLattes();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }

}