import React from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout/Layout';
//Autenticacao
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
//Providers
import IdiomaContextProvider from './context/idiomaContext/idiomaContextProvider';
import InfoContextProvider from './context/infoContext/infoContextProvider';
import LoadingContextProvider from './context/loadingContext/loadingContextProvider';
import MessageContextProvider from './context/messageContext/messageContextProvider';
//Pages
import { Home } from './pages/Home/Home';
import { IndicadoresPorAno } from './pages/IndicadoresProdBib/IndicadoresPorAno';
import { IndicadoresPorInstituicao } from './pages/IndicadoresProdBib/IndicadoresPorInstituicao';
import { IndicadoresPorProfessor } from './pages/IndicadoresProdBib/IndicadoresPorProfessor';
import { IndicadoresOrientacaoPorAno } from './pages/IndicadoresOrientacao/IndicadoresOrientacaoPorAno';
import { IndicadoresOrientacaoPorInstituicao } from './pages/IndicadoresOrientacao/IndicadoresOrientacaoPorInstituicao';
import { IndicadoresInovacaoPorAno } from './pages/IndicadoresInovacao/IndicadoresInovacaoPorAno';
import { IndicadoresInovacaoPorInstituicao } from './pages/IndicadoresInovacao/IndicadoresInovacaoPorInstituicao';
import { IndicadoresAssuntos } from './pages/IndicadoresAssuntos/IndicadoresAssuntos';
import { ListaPesquisadores } from './pages/ListaPesquisadores/ListaPesquisadores';
import { ListaDescredenciados } from './pages/ListaPesquisadores/ListaDescredenciados';
import { FormacaoAcademica } from './pages/FormacaoAcademica/FormacaoAcademica';
import { MaisProdutivos } from './pages/MaisProdutivos/MaisProdutivos';
import { FichaAvaliacaoPesquisador } from './pages/FichaAvaliacaoPesquisador/FichaAvaliacaoPesquisador';
import { AssuntoPesquisadores } from './pages/AssuntoPesquisadores/AssuntoPesquisadores';
import { Sobre } from './pages/Sobre/Sobre';
import PrimeiroAcesso from "./pages/Autenticacao/PrimeiroAcesso";
import ExcluirConta from "./pages/Autenticacao/ExcluirConta";
import { Perfil } from "./pages/Autenticacao/Perfil";
//Adm
import { Adm } from './pages/Adm/Adm';
import { downloadLattes } from './pages/Adm/downloadLattes';
import { excluiEstatisticas } from './pages/Adm/excluiEstatisticas';

export default function App() {
                      
    return (
        <IdiomaContextProvider>
            <InfoContextProvider>
                <LoadingContextProvider>
                    <MessageContextProvider>
                    <Layout>
                        <Route exact path='/' component={Home} />
                        <Route path='/sobre' component={Sobre} />
                        <Route path='/indInstituicao' component={IndicadoresPorInstituicao} />
                        <Route path='/indAno' component={IndicadoresPorAno} />
                        <AuthorizeRoute exact path='/indProfessor' component={IndicadoresPorProfessor} />
                        <Route path='/indOrientacaoInst' component={IndicadoresOrientacaoPorInstituicao} />
                        <Route path='/indOrientacaoAno' component={IndicadoresOrientacaoPorAno} />
                        <Route path='/indInovacaoInst' component={IndicadoresInovacaoPorInstituicao} />
                        <Route path='/indInovacaoAno' component={IndicadoresInovacaoPorAno} />
                        <Route path='/indAssuntos' component={IndicadoresAssuntos} />
                        <Route path='/ListaPesquisadores' component={ListaPesquisadores} />
                        <Route path='/ListaDescredenciados' component={ListaDescredenciados} />
                        <Route path='/FormacaoAcademica' component={FormacaoAcademica} />
                        <Route path='/MaisProdutivos' component={MaisProdutivos} />
                        <Route path='/fichaAvaliacaoPesq' component={FichaAvaliacaoPesquisador} />
                        <Route path='/AssuntoPesq' component={AssuntoPesquisadores} />
                        <AuthorizeRoute path='/downloadLattes' component={downloadLattes} />
                        <AuthorizeRoute path='/excluiEstatisticas' component={excluiEstatisticas} />
                        <AuthorizeRoute path='/adm' component={Adm} />
                        <Route path='/primeiroAcesso' component={PrimeiroAcesso} />
                        <Route path='/excluirConta' component={ExcluirConta} />
                        <AuthorizeRoute path='/perfil' component={Perfil} />
                        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    </Layout>
                 </MessageContextProvider>
                </LoadingContextProvider>
            </InfoContextProvider>
        </IdiomaContextProvider>
    );
}

