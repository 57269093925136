import React, { useState, useContext, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, DropdownItem, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from '../api-authorization/LoginMenu';
import './NavMenu.css';
import Logo from '../Logo/Logo';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';

export function NavMenu(props) {

    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);
    const [collapsed, setCollapsed] = useState(true);

    const [mostraPerfilEgresso, setMostraPerfilEgresso] = useState(false);

    useEffect(() => {
        //componentDidMount
        if ((infoContext.user != null) && (infoContext.user.profile != null) &&(infoContext.user.profile.admin)) {
            setMostraPerfilEgresso(true);
        }
    }, [infoContext.user]); 

    function toggleNavbar() {
        setCollapsed(!collapsed);
        //#01420a
    }
    //<Navbar.Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} >

    function renderAll() {
        let contentsEgresso = null;
        if (mostraPerfilEgresso) {
            contentsEgresso =
                                        <LinkContainer to={'/ListaDescredenciados'}>
                                            <NavDropdown.Item >
                                                <span><i className='fa fa-user'></i> {idCt.t("Descredenciados", "Output Professors")} </span>
                                            </NavDropdown.Item >
                                        </LinkContainer>  
        }
        return (
            <header>
                <Navbar collapseOnSelect className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" variant="light" bg='light' expand="lg">
                    <Container style={{ maxWidth: "980px" }}>
                        <Navbar.Brand>
                            <Nav.Link style={{ padding: "0px", marginRight: "5px", color: "black" }} as={Link} to={'/'} >
                                <Logo />
                            </Nav.Link>
                        </Navbar.Brand>
                        <Navbar.Toggle className="mr-2" aria-controls="navbar-midi" onClick={toggleNavbar} />
                        <Navbar.Collapse className="d-sm-inline-flex flex-sm-row-reverse" >
                            <ul className="navbar-nav flex-grow">
                                <Nav.Item as="li">
                                    <LinkContainer to={'/'}>
                                        <Nav.Link>
                                            <i className="fa fa-th"></i> {idCt.t("Home", "Home")}
                                        </Nav.Link>
                                    </LinkContainer>
                                </Nav.Item>
                                <NavDropdown title={<span><i className='fa fa-pie-chart'></i> {idCt.t("Indicadores", "Indicators")} </span>}
                                    id="indicadores-dropdown">
                                    <NavDropdown title={<span><i className='fa fa-line-chart'></i> {idCt.t("Produção Bibliográfica", "Bibliographic Production")} </span>} id="proddocente-dropdown"
                                        className='dropdown-submenu' drop='right' key='right' >
                                        <LinkContainer to={'/indInstituicao'}>
                                            <NavDropdown.Item>
                                                {idCt.t("Consulta por Campus", "Query by Campus")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/indAno'}>
                                            <NavDropdown.Item>
                                                {idCt.t("Consulta por Período", "Query by period")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/indProfessor'}>
                                            <NavDropdown.Item>
                                                {idCt.t("Consulta por Docente", "Query by Professor")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                    </NavDropdown>
                                    <NavDropdown title={<span><i className='fa fa-bar-chart'></i> {idCt.t("Orientações", "Orientation")} </span>} id="prodorient-dropdown"
                                        className='dropdown-submenu' drop='right' key='right' >
                                        <LinkContainer to={'/indOrientacaoInst'}>
                                            <NavDropdown.Item>
                                                {idCt.t("Consulta por Campus", "Query by Campus")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/indOrientacaoAno'}>
                                            <NavDropdown.Item>
                                                {idCt.t("Consulta por Período", "Query by period")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                    </NavDropdown>
                                    <NavDropdown title={<span><i className='fa fa-area-chart'></i> {idCt.t("Inovação", "Innovation")} </span>} id="proddicente-dropdown"
                                        className='dropdown-submenu' drop="right" key="right">
                                        <LinkContainer to={'/indInovacaoInst'}>
                                            <NavDropdown.Item>
                                                {idCt.t("Consulta por Campus", "Query by Campus")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/indInovacaoAno'}>
                                            <NavDropdown.Item>
                                                {idCt.t("Consulta por Período", "Query by period")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                    </NavDropdown>
                                    <NavDropdown.Divider />
                                    <LinkContainer to={'/FormacaoAcademica'}>
                                        <NavDropdown.Item >
                                            <span><i className='fa fa-graduation-cap'></i> {idCt.t("Formação Acadêmica", "Academic Education")}</span>
                                        </NavDropdown.Item >
                                    </LinkContainer>
                                    <LinkContainer to={'/indAssuntos'}>
                                        <NavDropdown.Item >
                                            <span><i className='fa fa-comments'></i> {idCt.t("Temas de Pesquisa", "Research topics")} </span>
                                        </NavDropdown.Item >
                                    </LinkContainer>
                                </NavDropdown>
                                <NavDropdown title={<span><i className='fa fa-users'></i> {idCt.t("Docentes", "Professors")} </span>}
                                    id="pesquisadores-dropdown">
                                    <LinkContainer to={'/ListaPesquisadores'}>
                                        <NavDropdown.Item >
                                            <span><i className='fa fa-vcard-o'></i> {idCt.t("Consulta por Nome", "Query by Name")} </span>
                                        </NavDropdown.Item >
                                    </LinkContainer>
                                    <LinkContainer to={'/AssuntoPesq'}>
                                        <NavDropdown.Item >
                                            <span><i className='fa fa-book'></i> {idCt.t("Consulta por Temas de Pesquisa", "Query by Research topics")}</span>
                                        </NavDropdown.Item >
                                    </LinkContainer>
                                    <NavDropdown.Divider />
                                    <LinkContainer to={'/MaisProdutivos'}>
                                        <NavDropdown.Item >
                                            <span><i className='fa fa-angellist'></i> {idCt.t("Produção Científica", "Scientific Production")}</span>
                                        </NavDropdown.Item >
                                    </LinkContainer>
                                    <LinkContainer to={'/fichaAvaliacaoPesq'}>
                                        <NavDropdown.Item >
                                            <span><i className='fa fa-calendar-check-o'></i> {idCt.t("Avaliação Pesquisador (Editais PROPI)", "Scientific Production")}</span>
                                        </NavDropdown.Item >
                                    </LinkContainer>
                                    {contentsEgresso}
                                </NavDropdown>                               
                                <LoginMenu>
                                </LoginMenu>
                            </ul>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    return (renderAll());
}
