import React, { useContext } from 'react';
import './CabecalhoMenu.css'
import { Container } from 'reactstrap';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export default function CabecalhoMenu() {

    const idCt = useContext(IdiomaContext);

    function selectIdioma() {
        let IdiomaActive = <button type="button" className="link-button-language-en" onClick={() => idCt.changeLanguage('en')}> ENGLISH</button>;
        if (idCt.idioma === 'en') {
            //IdiomaActive = <li className="language-pt-br"><a title="IFTM Website versão Português" onClick={() => idCt.changeLanguage('pt-br')} href="javascript:void(0)">Português</a></li>;
            IdiomaActive = <button type="button" className="link-button-language-pt" onClick={() => idCt.changeLanguage('pt-br')}> PORTUGUÊS</button>;
        }
        return (
            <ul id="language" className="pull-right">
                {IdiomaActive}
            </ul>
        );
    }

    function cabecalhoBrasil() {

        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return (
                <div></div>    
            );
        }
        //<li className="list-item first"><a href="http://www.simplifique.gov.br" className="link-barra">Simplifique!</a></li>
        //<li className="list-item"><a href="http://www.planalto.gov.br/legislacao" className="link-barra">Legislação</a></li>
        return (
            <div style={{ backgroundColor: '#f1f1f1' }} >
                <Container>
                    <div id="barra-brasil">
                        <div id="wrapper-barra-brasil">
                            <div className="brasil-flag">
                                <a href="https://gov.br" className="link-barra">Brasil</a>
                            </div>
                            <nav>
                                <ul id="lista-barra-brasil" className="list">
                                    <li><a href="#" id="menu-icon"></a></li>                                    
                                    <li className="list-item"><a href="https://www.gov.br/pt-br/participacao-social/" className="link-barra">Participe</a></li>
                                    <li className="list-item"><a href="https://www.gov.br/acessoainformacao/pt-br" className="link-barra">Acesso à informação</a></li>                                    
                                    <li className="list-item last last-item"><a href="https://gov.br/pt-br/canais-do-executivo-federal" className="link-barra">Canais</a></li>
                                </ul>
                            </nav>
                            <span id="brasil-vlibras">
                                <a className="logo-vlibras" id="logovlibras" href="#"></a>
                                <span className="link-vlibras"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=" className="barralazy" data-src="//barra.brasil.gov.br/imagens/vlibras.gif" width="132" height="116" />
                                </span>
                            </span>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }

    function cabecalhoTexto() {
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return (
                <div id="logo" className="span8" style={{ width: "100%" }}>
                    <span className="portal-title-1">IFTM - Uberaba Parque Tecnológico</span>
                    <span style={{ fontSize: "1.5em", fontWeight: "bold" }} >{idCt.t("Observatório IFTM", "Observatory of the IFTM")}</span>
                    <span className="portal-description">{idCt.t("Ministério da Educação", "Ministry of Education")}</span>
                </div>
            );
        }
        return (
            <div id="logo" className="span8" style={{ width: "100%" }}>
                <span className="portal-title-1">IFTM Campus Avançado Uberaba Parque Tecnológico</span>
                <h1 className="portal-title corto">{idCt.t("Observatório IFTM", "Observatory of the IFTM")}</h1>
                <span className="portal-description">{idCt.t("Ministério da Educação", "Ministry of Education")}</span>
            </div>
        );
    }

    return (
        <div>
            {cabecalhoBrasil()}
            <div className="corbarraiftm">
                <Container className="divlogoiftm" style={{ maxWidth: "980px" }}>
                    {cabecalhoTexto()}                    
                    <div style={{ width: "80px", marginRight: "1px" }}>
                        {selectIdioma()}
                    </div>
                </Container>
            </div>
        </div>

    )
}