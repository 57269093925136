import React, { useContext, useState, useRef, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import { SelecProfessor } from '../../components/SelectProfessor/SelecProfessor';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import FichaPesquisadorOnline from '../../components/Pesquisadores/FichaPesquisadorOnline';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export function FichaAvaliacaoPesquisador(props) {

    const idCt = useContext(IdiomaContext);

    let QualProfessorInicial = {
        id: 0,
        nome: "",
        lattesEndereco: "",
    }

    const now = new Date();
    let QualAnoInicial = now.getFullYear() - 5;
    let QualAnoFinal = now.getFullYear();

    const [QualProfessor, setQualProfessor] = useState(QualProfessorInicial);
    const [LinkLattes, setLinkLattes] = useState(null);

    const [AnoInicial, setAnoInicial] = useState(QualAnoInicial);
    const [AnoFinal, setAnoFinal] = useState(QualAnoFinal);

    const [Pesquisou, setPesquisou] = useState(false);
    const prevPesquisou = usePrevious(Pesquisou);

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    function handlePesquisar(event) {
        event.preventDefault();
        setPesquisou(!Pesquisou);
    }

    function renderCabecalho() {
        return (
            <Row>
                <Col sm={12}>
                    <h2>{idCt.t("Ficha de Avaliação do Pesquisador", "Researcher Evaluation Form")}</h2>
                    <span style={{ fontSize: "0.75em", color: '#666666' }} >
                        {idCt.t("Esta ficha é utilizada em alguns editais da Pró-Reitoria de Pesquisa, Pós-Graduação e Inovação", "This form is used in some notices of the Dean of Research, Graduate Studies and Innovation")}
                    <br />
                        {idCt.t("Os dados da ficha são obtidos da Plataforma Lattes em tempo real", "The data of the form are obtained from the Lattes Platform in real time")}
                    </span>
                    <br />
                    <span style={{ fontSize: "0.75em", color: '#ff6666' }} >
                        {idCt.t("Os estratos do Qualis Periódicos são do Quadriênio 2017-2020", "The Qualis Periodicos classifiers used are Quadriênio 2017-2020")}
                    </span>
                    <PainelConteudo legenda={idCt.t("Pesquisar", "Search")}
                        texto={
                            <Container >
                                <Row>
                                    <Col sm={5}>
                                        <span>{idCt.t("Informe o Ano Inicial da Ficha de Avaliação:", "Enter the Starting Year of the Assessment Form:")}</span><br />
                                        <InputMask style={{ width: "100%" }} value={AnoInicial} onChange={(e) => setAnoInicial(e.target.value)} mask="9999" placeholder="9999" />
                                        <span style={{ fontSize: "10px" }}><i>Conforme data no <b>Edital. Não considere</b> o período de Licença maternidade.</i></span>
                                    </Col>
                                    <Col sm={1}>
                                        <strong>{idCt.t("a", "to")}</strong><br />
                                    </Col>
                                    <Col sm={6}>
                                        <span>{idCt.t("Informe o Ano Final da Ficha de Avaliação:", "Enter the Final Year of the Assessment Form:")}</span><br />
                                        <InputMask style={{ width: "100%" }} value={AnoFinal} onChange={(e) => setAnoFinal(e.target.value)} mask="9999" placeholder="9999" />
                                        <span style={{ fontSize: "10px" }}><i>Conforme data no <b>Edital</b>.</i></span>
                                        <br /><br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={5}>
                                        <span>{idCt.t("Selecione o Docente:", "Choose the Researcher:")}</span><br />
                                        <SelecProfessor ProfessorSelecionado={QualProfessor.id} AtualizaQualProfessor={setQualProfessor} />
                                    </Col>
                                    <Col sm={1}>
                                        <strong>{idCt.t("ou", "or")}</strong><br />
                                    </Col>
                                    <Col sm={6}>
                                        <span>{idCt.t("Informe o Link do Lattes do Pesquisador:", "Enter the Lattes Link:")}</span><br />
                                        <InputText style={{ width: "100%" }} value={LinkLattes} onChange={(e) => setLinkLattes(e.target.value)} />
                                        <span style={{ fontSize: "10px" }}><i>Exemplo: http://lattes.cnpq.br/0989262262491615 </i></span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <br />
                                        <div style={{ textAlign: "left" }}>
                                            <Button label={idCt.t("Pesquisar", "Search")} onClick={handlePesquisar.bind(this)} className="p-button-success" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        } />
                </Col>
            </Row>
            );
    }

    function renderAll() {
        if ((QualProfessor.id == 0) && (!LinkLattes) ) {
            return (
                <Container >
                    {renderCabecalho()}
                    <Row>
                        <Col sm={12}>
                            <PainelConteudo legenda={idCt.t("Ficha", "Form")}
                                texto={
                                    <Container >
                                        <Row>
                                            <Col sm={12}>
                                                <p>{idCt.t("Selecione o pesquisador ou informe o endereço lattes.", "Select the searcher or enter the address lattes.")}</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                } />
                        </Col>
                    </Row>
                </Container>
            );
        }
        else if (prevPesquisou === Pesquisou) {
            return (
                <Container >
                    {renderCabecalho()}
                    <Row>
                        <Col sm={12}>
                            <PainelConteudo legenda={idCt.t("Ficha", "Form")}
                                texto={
                                    <Container >
                                        <Row>
                                            <Col sm={12}>
                                                <p>{idCt.t("Após informar o item da busca, clique em Pesquisar.", "After informing the search item, click Search.")}</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                } />
                        </Col>
                    </Row>
                </Container>
            );
        }
        let strLattes = LinkLattes;
        if (QualProfessor.id != 0) {
            strLattes = QualProfessor.lattesEndereco;
        }
        return (
            <Container >
                {renderCabecalho()}
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda={idCt.t("Ficha", "Form")}
                            texto={
                                <Container >
                                    <Row>
                                        <Col sm={12}>
                                            <FichaPesquisadorOnline 
                                                QualLinkLattes={strLattes}
                                                anoInicio={AnoInicial}
                                                anoFim={AnoFinal}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            } />
                    </Col>
                </Row>
            </Container>
        );
    }

    return (renderAll());
}
