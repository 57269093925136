import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ApplicationPaths } from '../../components/api-authorization/ApiAuthorizationConstants';

// Components
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export default function PrimeiroAcesso(props) {

    const idCt = useContext(IdiomaContext);
    const registerPath = `${ApplicationPaths.Register}`;
    const loginPath = `${ApplicationPaths.Login}`;

    return (
          <Container >
              <Row>
                  <Col sm={12}>
                    <h2>{idCt.t("Primeiro acesso", "First login")}</h2>
                  </Col>
              </Row>
              <Row>
                  <Col sm={12}>
                    {idCt.t("Somente docentes do IFTM possuem conta de usuário neste site.", "Only IFTM professors have a user account on this site.")}
                  </Col>
              </Row>
              <Row>
                  <Col sm={12}>
                        <PainelConteudo legenda="Docente"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <p>
                                            Caro <strong>docente</strong>, o seu email de acesso ao sistema é o email institucional.<br />
                                            Para conferir o seu email de acesso, basta acessar <Link to={'/ListaPesquisadores'}>Docentes » Consulta por Nome</Link>. <br />
                                            O acesso ao sistema é feito pelo Google. <br />
                                            Para acessar o sistema, <b><Link to={loginPath}>clique aqui</Link></b>.
                                        </p>    
                                    </Col>
                                </Row>
                            } />
                </Col>
              </Row>
          </Container>
    );
}
