import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Switch from '@material-ui/core/Switch';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { deepOrange } from '@material-ui/core/colors';
import { Button } from 'primereact/button';
import './FichaPesquisadorOnline.css';

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import "../Documentos/calibri-normal";
import "../Documentos/DocUtil";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import LoadingContext from '../../context/loadingContext/loadingContext';
import MessageContext from '../../context/messageContext/messageContext';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    dividerFullWidth: {
        margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
        margin: `5px 0 0 ${theme.spacing(9)}px`,
    },
}));

export default function FichaPesquisadorOnline(props) {

    const classes = useStyles();

    //Contexto
    const loadingContext = useContext(LoadingContext);
    const messageContext = useContext(MessageContext);

    //Construtor
    const [Ficha, setFicha] = useState(null);
    const [Error, setError] = useState(null);
    const [state, setState] = useState({
        checkedPontuados: false,
        expandedRows: null,
    });

    useEffect(() => {
        //componentDidMount e componentDidUpdate
        loadingContext.showLoading();
        CarregarDados();
        loadingContext.hideLoading();
    }, []);

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    function CarregarDados() {
        let StringFetch = 'api/Pesquisadores/CurriculoLattesPontos?LattesEndereco=' + props.QualLinkLattes + '&anoInicio=' + props.anoInicio + '&anoFim=' + props.anoFim;
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                setFicha(data);
                setError(null);
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro. Por favor, confira a plataforma Lattes e tente novamente.");
                setError(error);
            });
    }

    const headerGroupTemplate = (data) => {
        return (
            <React.Fragment>                
                <span><b><i>{data.grupo}</i></b></span>
            </React.Fragment>
        );
    }

    const footerGroupTemplate = (data) => {
        return null;
        /*
        return (
            <React.Fragment>
                <td colSpan="3" style={{ textAlign: 'right' }}>Total de Pontos</td>
                <td>{"xxx"}</td>
            </React.Fragment>
        );
        */
    }

    const itemTemplate = (rowData) => {
        return (
            <React.Fragment>
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    disableSelection={true}
                >
                    <TreeItem nodeId={rowData.item + "1"} label={rowData.item}>
                        {
                            rowData.descricoes.map((element, index) => 
                                <TreeItem nodeId={rowData.item + "2"} label={<span>&#9679; {element}</span>} />
                            )
                        }
                    </TreeItem>
                </TreeView>
            </React.Fragment>
        );
    }

    function renderTabela() {
        //sortMode="single" sortField="representative.name" sortOrder={1}
        let footer = <span style={{ fontSize: '16px' }}><b> Total de Pontos: {Ficha.totalPontos}</b></span>;
        let cabecalho = <span>Critério Avaliado de {Ficha.anoInicio} a  {Ficha.anoFim}</span>;
        let dados = Ficha.criteriosAvaliados;
        if (state.checkedPontuados) {
            dados = Ficha.criteriosAvaliadosPontuados;
        }

        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return (
                <div className="datatable-rowgroup-demo">
                    <DataTable value={dados} rowGroupMode="subheader" groupField="grupo"
                        rowGroupHeaderTemplate={headerGroupTemplate} footer={footer} rowGroupFooterTemplate={footerGroupTemplate} >
                        <Column field="item" header={cabecalho} body={itemTemplate}></Column>
                        <Column field="pontos" header="Ponto Atrib" style={{ textAlign: 'right', width: '4em' }}></Column>
                    </DataTable>
                </div>
            );
        }

        return (
            <div className="datatable-rowgroup-demo">
                    <DataTable value={dados} rowGroupMode="subheader" groupField="grupo"                    
                               rowGroupHeaderTemplate={headerGroupTemplate} footer={footer} rowGroupFooterTemplate={footerGroupTemplate} >
                        <Column field="item" header={cabecalho} body={itemTemplate}></Column>
                        <Column field="valorUnitario" header="Pontuação" style={{ textAlign: 'right', width: '7em' }}></Column>
                        <Column field="qtde" header="Quantidade" style={{ textAlign: 'right', width: '7em' }}></Column>
                        <Column field="pontos" header="Pontuação Atribuída" style={{ textAlign: 'right', width: '7em' }}></Column>
                    </DataTable>
            </div>
            );

    }

    function renderAll() {
        if (loadingContext.loading) {
            return (
                <div>
                    <p><i className="fa fa-refresh fa-spin"></i> <em>Lendo dados da Plataforma Lattes</em></p>
                </div>
            );
        }
        else if (Error) {
            return (
                <Container>
                    <Row>
                        <Col sm={12}>
                            Erro ao importar os dados para elaboração da ficha.
                        </Col>
                    </Row>
                </Container>
            );
        }
        else if (Ficha == null) {
            return (
                <div>
                    <p><i className="fa fa-refresh fa-spin"></i> <em>Lendo dados da Plataforma Lattes</em></p>
                </div>
            );
        }
        let strfoto = <Avatar>{Ficha.nome.substring(0, 1)}</Avatar>; 
        if (Ficha.fotoLattes) {
            strfoto = <Avatar alt={Ficha.nome} src={Ficha.fotoLattes} />;
        }
        let strLattes = Ficha.lattesEndereco + ". Última atualização: " + Ficha.dataAtualizacaoLattes; 
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            strLattes = "Lattes: " + Ficha.dataAtualizacaoLattes; 
        }
        return (
            <Container>
                <Row>
                    <Col sm={12} className="fichaLattes">
                        <List className={classes.root}>
                            <ListItem>
                                <ListItemAvatar>
                                    {strfoto}
                                </ListItemAvatar>
                                <ListItemText primary={Ficha.nome} secondary={strLattes} />
                            </ListItem>
                        </List>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <hr size="1" />
                    </Col>
                </Row>
                <Row>
                    <Col sm={10}>
                        Exibição dos critérios avaliados:  todos
                        <Switch
                            checked={state.checkedPontuados}
                            onChange={handleChange}
                            name="checkedPontuados"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        /> 
                        somente pontuados   
                    </Col>
                    <Col sm={2}>
                        <div style={{ textAlign: "right" }}>
                            <Button label={"Gerar PDF"} onClick={() => ExportarPDF()} className="p-button-danger" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        {renderTabela()}
                    </Col>
                </Row>
            </Container>
        );

    }

    function ExportarPDF() {
        const doc = new jsPDF("portrait", "pt", "A4");
        doc.setFont('calibri', 'normal');
        //Tabela
        const headersTable = [["Critério Avaliado de " + Ficha.anoInicio + " a " + Ficha.anoFim, "Pontuação", "Pontuação Atribuída"]];
        var bodyData = [];
        var DescGrupo = "";
        for (var i = 0; i < Ficha.criteriosAvaliadosPontuados.length; i++) {
            var row = []
            if (DescGrupo != Ficha.criteriosAvaliadosPontuados[i].grupo) {
                row.unshift({
                    colSpan: 3,
                    content: Ficha.criteriosAvaliadosPontuados[i].grupo,
                    styles: { valign: 'middle', halign: 'center' },
                })
                bodyData.push(row);
            }
            DescGrupo = Ficha.criteriosAvaliadosPontuados[i].grupo;
            bodyData.push([Ficha.criteriosAvaliadosPontuados[i].item, Ficha.criteriosAvaliadosPontuados[i].valorUnitario, Ficha.criteriosAvaliadosPontuados[i].pontos]);
        }  
        let contentPDF = {
            head: headersTable,
            body: bodyData,
            didDrawPage: function (data) {
                // Header
                doc.setFontSize(14)
                doc.setTextColor(40)
                /*
                if (base64Img) {
                    doc.addImage(base64Img, 'JPEG', data.settings.margin.left, 15, 10, 10)
                }*/
                doc.myText("Observatório IFTM", { align: "center" }, 0, 22);
                doc.myText("Ficha de Avaliação do Pesquisador", { align: "center" }, 0, 40);
                doc.setDrawColor(0, 100, 0);
                doc.line(data.settings.margin.left + 2, 50, 550, 50);

                doc.setFontSize(11);
                doc.text('Nome: ' + Ficha.nome, data.settings.margin.left + 5, 65);
                doc.text('Lattes: ' + Ficha.lattesEndereco + ". Última atualização: " + Ficha.dataAtualizacaoLattes, data.settings.margin.left + 5, 80);
                doc.text('Data: ' + Ficha.dataDocumento, data.settings.margin.left + 5, 95);

                // Footer
                var str = 'Documento gerado em http://obsiftm.midi.upt.iftm.edu.br  -  Página ' + doc.internal.getNumberOfPages()
                doc.setFontSize(10)
                var pageSize = doc.internal.pageSize
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                doc.line(data.settings.margin.left, pageHeight - 20, 250, pageHeight - 20);
                doc.text(str, data.settings.margin.left, pageHeight - 10)
            },
            margin: { top: 110 },
        };
        doc.autoTable(contentPDF);
        let finalY = doc.previousAutoTable.finalY;
        doc.myText("Total de pontos: " + Ficha.totalPontos, { align: "center" }, 0, finalY + 20);
        var NomePDF = "FichaAvaliacao_" + Ficha.nome + ".pdf";
        doc.save(NomePDF);
    }

    return (
        renderAll()
    );

}