import React, { useState, useContext, useEffect } from 'react';
//Contexto
import IdiomaContext from '../../context/idiomaContext/idiomaContext';
// Panel
import { Panel } from 'primereact/panel';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ModalMensagem from '../ModalMensagem/ModalMensagem';
import CronJobLattes from '../AtualizacaoLattes/CronJobLattes';
// CSS
import "./PainelConteudo.css";

export default function PainelConteudo(props) {

    const idCt = useContext(IdiomaContext);

    const [ExibeInformacao, setExibeInformacao] = useState(false);
    const [MsgInformacao, setMsgInformacao] = useState(null);
    const [Botao, setBotao] = useState(null);

    //Datas
    //const DataLattes = "dados extraidos em 12/03/2020";
    //const QualisCapes = "O Qualis Periodicos 2017-2020 sofrera uma grande mudanca em relacao ao Quadrienio 2013-2016. Os dados usados no Observatorio NAO SAO OFICIAIS. o Qualis Periodicos utilizado e uma estratificacao previa discutida no Seminario de Meio Termo de 2019 e sofrera alteracoes.";    

    useEffect(() => {        
        Configurar();
        ConfigurarMsg();
    }, [props.legenda, props.texto]);

    function Configurar() {
        let contentBotao = <span></span>;
        if (props.mostraAjuda) {
            contentBotao =
                <div style={{ display: 'inline-block' }}>
                    <IconButton size="small" color="primary" aria-label="Ajuda" style={{ verticalAlign: "top", paddingLeft: "5px", paddingBottom: "0px", paddingTop: "0px" }}
                                onClick={() => { setExibeInformacao(true); }}>
                        <Icon fontSize="small">help</Icon>
                    </IconButton>
                </div>
        }
        setBotao(contentBotao);
    }    

    function renderAll() {
        let legenda = <span>{props.legenda}{Botao}</span>;
        let Sobre = <span>{idCt.t("Sobre ", "About ")} {props.legenda}</span>;

        return (
            <div>
                <div className="divConteudo">
                    <Panel header={legenda} toggleable={true} >
                        {props.texto}
                    </Panel>
                </div>
                <div>
                    <ModalMensagem updateShow={setExibeInformacao}
                        show={ExibeInformacao} titulo={Sobre}
                        mensagem=
                        {<div>
                            {MsgInformacao}
                        </div>
                        }
                    />
                </div>
            </div>
        );
    }

    return (
        renderAll()
    );

    function ConfigurarMsg() {
        let Msg = <span></span>;
        if (props.qualIndicador === "BOAS VINDAS") {
            Msg = BoasVindas();
        }
        else if (props.qualIndicador === "PROD BIB DOCENTE") {
            Msg = IndProducaoBibliograficaDocente();
        }
        else if (props.qualIndicador === "PROD PERIODICOS DOCENTE") {
            Msg = IndProducaoPeriodicosDocente();
        }
        else if (props.qualIndicador === "ORIENTACOES DOCENTE") {
            Msg = IndOrientacoesDocente();
        }
        else if (props.qualIndicador === "PERFIL DOCENTE") {
            Msg = IndPerfilDocente();
        }
        else if (props.qualIndicador === "REGISTRO DE SOFTWARE E PATENTES") {
            Msg = IndInovacao();
        }
        else if (props.qualIndicador === "DOCENTES COM PRODUCAO") {
            Msg = IndDocenteComPeriodico();
        }
        else if (props.qualIndicador === "DOCENTES COM INOVACAO") {
            Msg = IndDocenteComInovacao();
        }
        setMsgInformacao(Msg);
    }

    function BoasVindas() {
        return (
            <div>
                {idCt.t("Os indicadores são ferramentas de gestão que quantificam o desempenho dos docentes do IFTM, sendo essenciais para o seu aprimoramento.",
                    "Indicators are management tools that quantify the performance of the IFTM professors and are essential for their improvement.")}
                <br />
                {idCt.t("A lista de docentes é extraída do Portal da Transparência do Governo Federal anualmente.",
                    "The list of teachers is extracted from the Federal Government's Transparency Portal annually.")}
                <br />
                {idCt.t("Os dados utilizados para a elaboração dos indicadores são extraídos da Plataforma Lattes mensalmente.",
                    "The data used for the preparation of the indicators are extracted from the Lattes Platform monthly.")}
                <br />
                {idCt.t("Para saber mais sobre cada indicador, basta clicar no icone ",
                        "To learn more about each indicator, just click on the icon ")}
                <div style={{ display: 'inline-block' }}>
                    <IconButton size="small" color="primary" aria-label="Ajuda"
                        style={{ verticalAlign: "top", paddingLeft: "5px", paddingBottom: "0px", paddingTop: "0px" }}>
                        <Icon fontSize="small">help</Icon>
                    </IconButton>
                </div>
                {idCt.t("(ao lado do titulo do indicador)", "(next to the indicator title)")}
            </div>
        );
    }

    function IndProducaoBibliograficaDocente() {
        return (
            <div>
                <b>{idCt.t("Objetivo: ", "Object: ")}</b>
                {idCt.t("Este indicador visa diagnosticar a producao cientifica do docente. Os tipos de producao a serem consideradas sao: artigo completo em evento; Resumo em evento; artigo em periodico; capitulo de livro e livro.",
                        "This indicator aims to diagnose the scientific production of the professor. The types of production to be considered are: complete article at an event; Event summary; journal article; book and book chapter.")}
                <br />
                <b>{idCt.t("Fonte dos dados: ", "Data source: ")}</b>
                <br />
                <i>{idCt.t("Docentes: ","Professors: ")}</i>
                {idCt.t("Portal da Transparência do Governo Federal com atualizacao anual.",
                        "Federal Government Transparency Portal with annual update.")}
                <br />
                <i>{idCt.t("Producao: ", "Production: ")}</i>
                {idCt.t("Plataforma Lattes ","Lattes Plataform ")}
                <CronJobLattes />
            </div>
        );
    }

    function IndProducaoPeriodicosDocente() {
        return (
            <div>
                <b>{idCt.t("Objetivo: ", "Object: ")}</b>
                {idCt.t("Este indicador visa diagnosticar a producao cientifica do docente apenas em periodicos. As quantidades sao separadas pelos estratos dos periodicos.",
                        "This indicator aims to diagnose the professor's scientific production only in journals. The quantities are separated by the categories of the journals.")}
                <br />
                <b>{idCt.t("Fonte dos dados: ", "Data source: ")}</b>
                <br />
                <i>{idCt.t("Docentes: ", "Professors: ")}</i>
                {idCt.t("Portal da Transparência do Governo Federal com atualizacao anual.",
                        "Federal Government Transparency Portal with annual update.")}
                <br />
                <i>{idCt.t("Producao: ", "Production: ")}</i>
                {idCt.t("Plataforma Lattes ", "Lattes Plataform ")}
                {idCt.t(" e Periodicos Qualis CAPES: ", " and Qualis CAPES Periodicals: ")}
                <br />
                <CronJobLattes />
            </div>
        );
    }

    function IndOrientacoesDocente() {
        return (
            <div>
                <b>{idCt.t("Objetivo: ", "Object: ")}</b>
                {idCt.t("Este indicador visa diagnosticar o quantitativo de orientacoes dos professores.",
                        "This indicator aims to diagnose the amount of orientation from professors.")}
                <br />
                <b>{idCt.t("Fonte dos dados: ", "Data source: ")}</b>
                <br />
                <i>{idCt.t("Docentes: ", "Professors: ")}</i>
                {idCt.t("Portal da Transparência do Governo Federal com atualizacao anual.",
                        "Federal Government Transparency Portal with annual update.")}
                <br />
                <i>{idCt.t("Orientacoes: ", "Orientations: ")}</i>
                {idCt.t("Plataforma Lattes ", "Lattes Plataform ")}
                <CronJobLattes />
            </div>
        );
    }

    function IndPerfilDocente() {
        return (
            <div>
                <b>{idCt.t("Objetivo: ", "Object: ")}</b>
                {idCt.t("Este indicador visa diagnosticar o perfil docente atraves dos assuntos mais pesquisados e divulgados em producoes cientificas.",
                        "This indicator aims to diagnose the teaching profile through the most researched and disseminated subjects in scientific productions.")}
                <br />
                <b>{idCt.t("Fonte dos dados: ", "Data source: ")}</b>
                <br />
                <i>{idCt.t("Docentes: ", "Professors: ")}</i>
                {idCt.t("Portal da Transparência do Governo Federal com atualizacao anual.",
                        "Federal Government Transparency Portal with annual update.")}
                <br />
                <i>{idCt.t("Producao: ", "Production: ")}</i>
                {idCt.t("Plataforma Lattes ", "Lattes Plataform ")}
                <CronJobLattes />
            </div>
        );
    }

    function IndInovacao() {
        return (
            <div>
                <b>{idCt.t("Objetivo: ", "Object: ")}</b>
                {idCt.t("Este indicador visa diagnosticar a producao docente em relacao a inovacao. Os produtos considerados sao: registro de software e patentes.",
                        "This indicator aims to diagnose teaching production in relation to innovation. The products considered are: software registration and patents.")}
                <br />
                <b>{idCt.t("Fonte dos dados: ", "Data source: ")}</b>
                <br />
                <i>{idCt.t("Docentes: ", "Professors: ")}</i>
                {idCt.t("Portal da Transparência do Governo Federal com atualizacao anual.",
                    "Federal Government Transparency Portal with annual update.")}
                <br />
                <i>{idCt.t("Producao: ", "Production: ")}</i>
                {idCt.t("Plataforma Lattes ", "Lattes Plataform ")}
                <CronJobLattes />
            </div>
        );
    }

    function IndDocenteComPeriodico() {
        return (
            <div>
                <b>{idCt.t("Objetivo: ", "Object: ")}</b>
                {idCt.t("Este indicador visa diagnosticar a quantidade de docentes que possuem alguma publicacao em periodicos, eventos, etc.",
                        "This indicator aims to diagnose the number of teachers who have some publication in journals or events.")}
                <br />
                <b>{idCt.t("Fonte dos dados: ", "Data source: ")}</b>
                <br />
                <i>{idCt.t("Docentes: ", "Professors: ")}</i>
                {idCt.t("Portal da Transparência do Governo Federal com atualizacao anual.",
                    "Federal Government Transparency Portal with annual update.")}
                <br />
                <i>{idCt.t("Producao: ", "Production: ")}</i>
                {idCt.t("Plataforma Lattes ", "Lattes Plataform ")}
                {idCt.t(" e Periodicos Qualis CAPES: ", " and Qualis CAPES Periodicals: ")}
                <br />
                <CronJobLattes />
            </div>
        );
    }

    function IndDocenteComInovacao() {
        return (
            <div>
                <b>{idCt.t("Objetivo: ", "Object: ")}</b>
                {idCt.t("Este indicador visa diagnosticar a quantidade de docentes que possuem algum registro de software ou patente.",
                        "This indicator aims to diagnose the number of professors who have a software or patent registration.")}
                <br />
                <b>{idCt.t("Fonte dos dados: ", "Data source: ")}</b>
                <br />
                <i>{idCt.t("Docentes: ", "Professors: ")}</i>
                {idCt.t("Portal da Transparência do Governo Federal com atualizacao anual.",
                    "Federal Government Transparency Portal with annual update.")}
                <br />
                <i>{idCt.t("Producao: ", "Production: ")}</i>
                {idCt.t("Plataforma Lattes ", "Lattes Plataform ")}
                <br />
                <CronJobLattes />
            </div>
        );
    }

}