import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import SelecInstituicoes from '../../components/SelecInstituicoes/SelecInstituicoes';
import SelecAssuntos from '../../components/SelecAssunto/SelecAssunto';
import PesquisadoresTemasPesquisa from '../../components/PesquisadoresTemasPesquisa/PesquisadoresTemasPesquisa';

export class AssuntoPesquisadores extends Component {
    displayName = AssuntoPesquisadores.name

  constructor(props) {
      super(props);
      let IFTM = {
          id: -1,
          nome: "IFTM",
          sigla: "IFTM",
          quantidadeProfessores: 0,
          quantidadeProfessoresLattes: 0
      };
      this.state = {
          QualAssunto: [],
          QualInstituicao: IFTM
      };
      this.updateInst = this.updateInst.bind(this);
      this.updateAssunto = this.updateAssunto.bind(this);
    }

  //Método para atualizar a instituição e assunto:
    updateInst = (QualInstituicao) => { this.setState({ QualInstituicao }); }
    updateAssunto = (QualAssunto) => { this.setState({ QualAssunto }); }

  render() {
    return (
        <Container >
            <Row>
                <Col sm={12}>
                    <h2>Consulta de Docentes de acordo com o assunto</h2>
                    <PainelConteudo legenda="Pesquisar"
                        texto={
                            <Container >
                                <Row>
                                    <Col sm={5}>
                                        Escolha o Campus:
                                        <SelecInstituicoes AtualizaQualInstituicao={this.updateInst} />
                                    </Col>
                                    <Col sm={7}>
                                        Escolha o assunto:
                                        <SelecAssuntos AtualizaQualAssunto={this.updateAssunto} />
                                    </Col>
                                </Row>
                            </Container>
                        } />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <span style={{ fontSize: '15px', fontWeight: 'bold' }}>Pontos: </span>
                    <span style={{ fontSize: '13px' }}>Ocorrência dos assuntos em trabalhos científicos.</span><br />
                </Col>
            </Row>

            <Row>
                <Col sm={12}>
                    <PainelConteudo legenda="Docentes"
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <PesquisadoresTemasPesquisa
                                        QualInstituicao={this.state.QualInstituicao.id}
                                        QualAssunto={this.state.QualAssunto} />
                                </Col>
                            </Row>
                        } />                    
                </Col>
            </Row>
        </Container >
    );
  }
}
