import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

// Components
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export function Home(props) {

    const idCt = useContext(IdiomaContext);
    
    return (
          <Container>
              <Row>
                  <Col sm={12}>
                    <h2>{idCt.t("Bem vindo ao Observatório IFTM", "Welcome to the Observatory of the IFTM")}</h2>                      
                  </Col>
              </Row>
              <Row>
                  <Col sm={6}>
                      <PainelConteudo legenda={idCt.t("Observatório", "Observatory")}
                          texto={
                              <div> 
                                  <a href="https://midi.upt.iftm.edu.br" target="_blank">
                                      <img style={{ height: '80px', width: 'auto', float: 'left', marginRight: '8px', marginLeft: '0px', marginTop: '0px' }} src="obs.png" border="0" />
                                  </a>                                  
                                  <p>
                                      {idCt.t(
                                          "O Observatório tem como principal objetivo o mapeamento das áreas de pesquisa, perfis de professores e elaboração de indicadores de pesquisa. O Observatório faz parte do Grupo de Pesquisa em Mineração da Dados e Imagens (MiDI) do IFTM Campus Avançado Uberaba Parque Tecnológico. As estatísticas são realizadas usando o currículo Lattes dos professores permanentes da instituição.",
                                          "The Observatory's main objective is the mapping of research areas, teacher profiles and the elaboration of research indicators. The Observatory is part of the the Data Mining and Image Research Group (MiDI) of the IFTM Campus Avançado Uberaba Parque Tecnológico. Statistics are performed using the Lattes curriculum of the permanent teachers of the institution.")}
                                       <br />
                                      {idCt.t(
                                          "Para maiores informações, ",
                                          "For more information, ")}
                                      <Link to='/sobre'>{idCt.t("clique aqui", "click here ")}</Link>.                                  
                                  </p>
                              </div>
                          }
                      />
                  </Col>
                  <Col sm={6}>
                      <PainelConteudo legenda="IFTM"
                          texto={
                              <div>
                                  <a href="https://www.iftm.edu.br" target="_blank">
                                      <img style={{ height: '80px', width: 'auto', float: 'left', marginRight: '8px', marginLeft: '0px', marginTop: '0px' }} src="IFTM.png" border="0" />
                                  </a>
                                  <p>
                                      {idCt.t(
                                          "O Instituto Federal de Educação, Ciência e Tecnologia do Triângulo Mineiro (IFTM) é composto, atualmente, pelos Campus Campina Verde, Ibiá, Ituiutaba, Paracatu, Patos de Minas, Patrocínio, Uberaba, Uberaba Parque Tecnológico, Uberlândia e Uberlândia Centro e pela Reitoria. A missão do IFTM é ofertar a Educação Profissional e Tecnológica por meio do Ensino, Pesquisa e Extensão.",
                                          "The Federal Institute of Education, Science and Technology of the Triangulo Mineiro (IFTM) is currently composed of Campina Verde, Ibiá, Ituiutaba, Paracatu, Patos de Minas, Sponsorship, Uberaba, Uberaba Technology Park, Uberlândia and Uberlândia Centro and the Rectory . IFTM's mission is to offer Professional and Technological Education through Teaching, Research and Extension.")}
                                      < br />
                                      {idCt.t(
                                          "Para maiores informações, acesse ",
                                          "For more information, visit ")} 
                                      <a href={"https://www.iftm.edu.br"} target="blank">iftm.edu.br</a>.
                                  </p>
                              </div>
                          }
                      />
                  </Col>
              </Row>
              <Row>
                  <Col sm={12}>
                    <PainelConteudo mostraAjuda={true} qualIndicador="BOAS VINDAS" legenda={idCt.t("Atualização dos Dados", "Data Update")}
                          texto={
                              <div>
                                  {idCt.t("Os indicadores são ferramentas de gestão que quantificam o desempenho dos docentes do IFTM, sendo essenciais para o seu aprimoramento.",
                                          "Indicators are management tools that quantify the performance of the IFTM professors and are essential for their improvement.")} 
                                  <br />
                                  {idCt.t("A lista de docentes é extraída do Portal da Transparência do Governo Federal anualmente.",
                                          "The list of teachers is extracted from the Federal Government's Transparency Portal annually.")} 
                                  <br /> 
                                  {idCt.t("Os dados utilizados para a elaboração dos indicadores são extraídos da Plataforma Lattes mensalmente.",
                                          "The data used for the preparation of the indicators are extracted from the Lattes Platform monthly.")}
                                  <br /> 
                                  {idCt.t("Para saber mais sobre cada indicador, basta clicar no ícone ",
                                          "To learn more about each indicator, just click on the icon ")}
                                  <div style={{ display: 'inline-block' }}>
                                      <IconButton size="small" color="primary" aria-label="Ajuda"
                                                  style={{ verticalAlign: "top", paddingLeft: "5px", paddingBottom: "0px", paddingTop: "0px" }}>
                                          <Icon fontSize="small">help</Icon>
                                      </IconButton>
                                  </div>
                                  {idCt.t("(ao lado do título do indicador)","(next to the indicator title)")}                                                                   
                              </div>
                          }
                      />
                  </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <PainelConteudo legenda={idCt.t("Entre em Contato", "Contact")}
                        texto={
                            <div>
                                {idCt.t("Você pode entrar em contato com a equipe de desenvolvimento do Observatório IFTM para relatar problemas, deixar sugestões ou comentários. Basta enviar um email para o líder do projeto.",
                                        "You can contact the IFTM Observatory development team to report problems, leave suggestions or comments. Just send an email to the project leader.")}                                
                                <br />
                                {idCt.t("Para ler as principais dúvidas que surgem sobre esta ferramenta e conferir as respostas de cada pergunta, ",
                                        "Read the main questions that arise about this tool and check the answers to each question, ")}
                                <Link to='/sobre'>{idCt.t("clique aqui", "click here ")}</Link>.
                            </div>
                        }
                    />
                </Col>
            </Row>
          </Container>
    );
}
