import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import SelecMultAnos from '../../components/SelecAnos/SelecMultAnos';
import Patente from '../../components/Patente/Patente';
import QtdeDocentePatente from '../../components/Patente/QtdeDocentePatente';
import CronJobLattes from '../../components/AtualizacaoLattes/CronJobLattes';

export class IndicadoresInovacaoPorAno extends Component {
    displayName = IndicadoresInovacaoPorAno.name

    constructor(props) {
        super(props);
        this.state = {
            QualAno: [],
            MostraAnos: ""
        };
        this.updateAno = this.updateAno.bind(this);
    }

    //Método para atualizar o ano:
    updateAno = (QualAno) => {
        let Mostra = "";
        for (let i = 0; i < QualAno.length; i++) {
            if (i === 0) {
                Mostra = " " + QualAno[i];
            }
            else {
                Mostra = Mostra + " ; " + QualAno[i];
            }
        }
        this.setState({ QualAno, MostraAnos: Mostra });
    }

    render() {
        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        <h2>Indicadores de Inovação dos Campi em {this.state.MostraAnos}</h2>
                        <CronJobLattes />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <PainelConteudo legenda="Pesquisar"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        Escolha o(s) ano(s):
                                        <SelecMultAnos AtualizaQualAno={this.updateAno} />
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <br />
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Observação: </span>
                        <span style={{ fontSize: '13px' }}>Os dados da estatística são relacionados ao docentes permanentes da Instituição. Com isso, alguns dados podem estar vinculados ao docente e não à Instituição.</span><br />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <PainelConteudo mostraAjuda={true} qualIndicador="REGISTRO DE SOFTWARE E PATENTES" legenda="Registro de Software e Patentes"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <Patente
                                            QualConsulta="PorAno"
                                            TipoGrafico="Quantidade"
                                            QualAno={this.state.QualAno} />
                                    </Col>
                                </Row>
                            } />
                    </Col>
                    <Col sm={6}>
                        <PainelConteudo mostraAjuda={true} qualIndicador="REGISTRO DE SOFTWARE E PATENTES" legenda="Registro de Software e Patentes"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <Patente
                                            QualConsulta="PorAno"
                                            TipoGrafico="PorProfessor"
                                            QualAno={this.state.QualAno} />
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <PainelConteudo mostraAjuda={true} qualIndicador="DOCENTES COM INOVACAO" legenda="Docentes com Inovação"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <p>Quantidade de docentes com no mínimo um (1) registro de software ou patente.</p>
                                        <QtdeDocentePatente
                                            QualConsulta="PorAno"
                                            TipoGrafico="Quantidade"
                                            QualAno={this.state.QualAno} />
                                    </Col>
                                </Row>
                            } />
                    </Col>
                    <Col sm={6}>
                        <PainelConteudo mostraAjuda={true} qualIndicador="DOCENTES COM INOVACAO" legenda="Docentes com Inovação"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <p>Percentual de docentes em relação ao campus com no mínimo um (1) registro de software ou patente.</p>
                                        <QtdeDocentePatente
                                            QualConsulta="PorAno"
                                            TipoGrafico="PorProfessor"
                                            QualAno={this.state.QualAno} />
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
            </Container>
        );
    }
}
