import React, { Component } from 'react';
import { Chart } from 'primereact/chart';

export default class Patente extends Component {
    anos = [];
    qtdAnos = 0;
    Campus = [];
    qtdCampus = 0;
    TituloGraficoParte1 = "Quantidade x ";
    TituloGraficoParte2 = "Software/Patente registrados";

    mapDataYears() {
        //Popula os anos
        this.anos = [];
        this.qtdAnos = 0;
        let a = this.state.EstatisticaPatente;
        for (let i = 0; i < a.length; i++) {
            this.anos.push(a[i].ano);
            this.qtdAnos++;
        }
        this.TituloGraficoParte2 = "Anos";
    }

    mapDataCampus() {
        //Popula os campus
        this.Campus = [];
        this.qtdCampus = 0;
        let a = this.state.EstatisticaPatente;
        for (let i = 0; i < a.length; i++) {
            this.Campus.push(a[i].instituicao.sigla);
            this.qtdCampus++;
        }
        this.TituloGraficoParte2 = "Campus";
    }

    mapDataMediaPorProfessor() {
        let a = this.state.EstatisticaPatente;
        for (let i = 0; i < a.length; i++) {
            a[i].registroSoftware = ((a[i].registroSoftware * 1.00) / a[i].instituicao.quantidadeProfessoresLattes).toFixed(3);
            a[i].registroSoftwareINPI = ((a[i].registroSoftwareINPI * 1.00) / a[i].instituicao.quantidadeProfessoresLattes).toFixed(3);
            a[i].patente = ((a[i].patente * 1.00) / a[i].instituicao.quantidadeProfessoresLattes).toFixed(3);
            a[i].patenteINPI = ((a[i].patenteINPI * 1.00) / a[i].instituicao.quantidadeProfessoresLattes).toFixed(3);
        }
        this.setState({ EstatisticaPatente: a });
        this.TituloGraficoParte1 = "Média por docente x ";
    }

    mapDataToChart() {
        //Popula as datas
        let finalData = {
            registroSoftware: [],
            registroSoftwareINPI: [],
            patente: [],
            patenteINPI: []
        };
        let TipoBarraGrafico = 'bar';

        let a = this.state.EstatisticaPatente;
        for (let i = 0; i < a.length; i++) {
            finalData.registroSoftware.push(a[i].registroSoftware);
            finalData.registroSoftwareINPI.push(a[i].registroSoftwareINPI);
            finalData.patente.push(a[i].patente);
            finalData.patenteINPI.push(a[i].patenteINPI);
        }
        let QuaisLabels = this.anos;
        if (this.props.QualConsulta === "PorAno") {
            QuaisLabels = this.Campus;
            TipoBarraGrafico = 'bar'; //'horizontalBar';
        }
        let stackedDataUpdated = {
            labels: QuaisLabels,
            datasets: [{
                type: TipoBarraGrafico,
                label: 'Software (Outro Registro)',
                backgroundColor: '#ff0000',
                data: finalData.registroSoftware
            }, {
                type: TipoBarraGrafico,
                label: 'Software (Registro no INPI)',
                backgroundColor: '#ff00bf',
                data: finalData.registroSoftwareINPI
            }, {
                type: TipoBarraGrafico,
                label: 'Patente (Outro Registro) ',
                backgroundColor: '#8000ff',
                data: finalData.patente
            },
            {
                type: TipoBarraGrafico,
                label: 'Patente (Registro no INPI)',
                backgroundColor: '#0040ff',
                data: finalData.patenteINPI
            }]
        };

        let stackedOptionsUpdated = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    stacked: true
                }],
                yAxes: [{
                    stacked: true
                }]
            }
        };

        this.setState({
            stackedData: stackedDataUpdated,
            stackedOptions: stackedOptionsUpdated
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            EstatisticaPatente: [],
            stackedData: {},
            stackedOptions: {}
        };
    }

    componentDidMount() {
        this.CarregarDados();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.QualInstituicao !== this.props.QualInstituicao) ||
            (prevProps.QualAno         !== this.props.QualAno)) {
            this.CarregarDados();
        }
    }

    CarregarDados() {
        this.setState({ loading: true });
        let StringFetch = "";
        if (this.props.QualConsulta === "PorAno") {
            if (this.props.QualAno == null)
                return;
            if (this.props.QualAno.length === 0)
                return;
            StringFetch = 'api/Indicadores/Patente?QuaisAnos=' + this.props.QualAno;//QualAno=' + this.props.QualAno;
        }
        else {
            if (this.props.QualInstituicao == null)
                return;
            if (this.props.QualInstituicao === -1)
                return;
            StringFetch = 'api/Indicadores/Patente?QualInstituicao=' + this.props.QualInstituicao;
        }
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ EstatisticaPatente: data, loading: false });
                this.ConfiguraDados();
            })
            .catch(error => this.setState({ error, loading: false }));
    }
       
    ConfiguraDados() {
        
        this.dataType = {
            registroSoftware: this.state.EstatisticaPatente.registroSoftware,
            registroSoftwareINPI: this.state.EstatisticaPatente.registroSoftwareINPI,
            patente: this.state.EstatisticaPatente.patente,
            patenteINPI: this.state.EstatisticaPatente.patenteINPI
        };

        if (this.props.QualConsulta === "PorAno") {
            this.mapDataCampus = this.mapDataCampus.bind(this);
        }
        else {
            this.mapDataYears = this.mapDataYears.bind(this);
        }
        if (this.props.TipoGrafico === "PorProfessor") {
            this.mapDataMediaPorProfessor = this.mapDataMediaPorProfessor.bind(this);
        }
        this.mapDataToChart = this.mapDataToChart.bind(this);

        this.optionsInstituicoes = [
            {
                label: "random",
                value: "random"
            }
        ];

        if (this.props.QualConsulta === "PorAno") {
            this.mapDataCampus();
        }
        else {
            this.mapDataYears();
        }   
        if (this.props.TipoGrafico === "PorProfessor") {
            this.mapDataMediaPorProfessor();
        }   
        this.mapDataToChart();
    }

    renderPatente() {
        return (
            <div>
                <div className="content-section implementation">
                    <p align="center"><b><i>{this.TituloGraficoParte1}{this.TituloGraficoParte2}</i></b></p>
                    <p>Clique no <strong>tipo de registro</strong> para inserir ou remover a seleção:</p>
                    <Chart height="300px" type="bar" data={this.state.stackedData} options={this.state.stackedOptions} />
                </div>
            </div>
        );
    }

    render() {
        const { loading, error } = this.state;

        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents = <div className="overlay">
                           <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                       </div>;
        }
        else {
            contents = this.renderPatente();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }

}