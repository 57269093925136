import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export function Sobre(props) {

    const idCt = useContext(IdiomaContext);

    return (
          <Container >
              <Row>
                  <Col sm={12}>
                    <h2>{idCt.t("Sobre o Observatório IFTM", "About the Observatory of the IFTM")}</h2>
                  </Col>
              </Row>
              <Row>
                  <Col sm={12}>
                    <PainelConteudo legenda={idCt.t("Histórico", "Historic")}
                        texto={
                            <div>
                              <p>
                                    {idCt.t("O Observatório IFTM foi idealizado em setembro de 2018, pelo docente Ernani Viriato de Melo (IFTM Campus Avançado Uberaba Parque Tecnológico). Ernani percebeu a necessidade de termos um único ambiente para entender e analisar o perfil pesquisador da instituição.",
                                          "The Observatory IFTM was conceived in September 2018, by Ernani Viriato Melo (IFTM). Melo realized the need to have a single environment to understand and analyze the profile of the program's professors.")}
                              </p>
                              <p>
                                  {idCt.t("Em maio de 2019 foi disponibilizada a primeira versão do Observatório. Desde então, esta ferramenta vem adquirindo novas funcionalidades para auxiliar na gestão e diagnóstico. Além de proporcionar novos projetos de ensino e pesquisa para alunos do IFTM.",
                                          "In May 2019, the first version of the Observatory was made available. Since then, this tool has acquired new features to assist in management and diagnosis. In addition to providing new teaching and research projects for IFTM students.")}
                              </p>
                            </div>
                        } />
                  </Col>                  
              </Row>
              <Row>
                  <Col sm={12}>
                      <PainelConteudo legenda={idCt.t("Perguntas Frequentes", "Common Questions")} 
                        texto={
                            <div>
                              <p><b>Como acesso o sistema?</b><br />
                                  Todos os docentes já possuem uma conta de usuário no sistema. A conta é vínculada ao email institucional.<br />
                                  Quem realiza a autenticação é o próprio Google.                               
                              </p>
                              <p><b>Sou docente e não estou no sistema. O que devo fazer?</b><br />
                                  Você deve enviar um email para o líder do projeto reportando o problema.<br />
                                  Isto pode ocorrer devido ao fato que verificamos apenas uma vez ao ano quais docentes deixaram ou entraram na instituição.
                              </p>
                              <p><b>Se eu, docente da instituição, alterar o meu currículo Lattes, automaticamente os indicadores serão alterados?</b><br />
                                  Não. Os indicadores são calculados uma vez ao mês. No máximo em 30 dias os indicadores serão atualizados após sua alteração na Plataforma Lattes.
                              </p>
                            </div>
                          } />
                  </Col>
              </Row>
              <Row>
                  <Col sm={12}>
                      <PainelConteudo legenda={idCt.t("Agradecimentos", "Acknowledgments")} 
                          texto={
                              <Row>
                                  <Col sm={12}>
                                      <span>Agradecemos aos vários alunos do IFTM que contribuíram para o desenvolvimento do Observatório.</span><br />
                                      <span>Agradecemos a equipe de TI do campus e da reitoria, em especial o Lorenzzo Egydio Mollinar da Cruz e Tiago Souza Silva.</span><br />
                                      <span>Agradecemos o apoio da Pró-Reitoria de Pesquisa, Pós-Graduação e Inovação do IFTM.</span><br />
                                  </Col>
                              </Row>
                          } />
                  </Col>
              </Row>
          </Container>
    );
}
