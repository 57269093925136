import { jsPDF } from "jspdf";

(function (API) {
    API.myText = function (txt, options, x, y) {
        options = options || {};
        if (options.align == "center") {
            // Get current font size
            var fontSize = this.internal.getFontSize();

            // Get page width
            var pageWidth = this.internal.pageSize.width;
            let txtWidth = this.getStringUnitWidth(txt) * fontSize / this.internal.scaleFactor;

            // Calculate text's x coordinate
            x = (pageWidth - txtWidth) / 2;
        }

        // Draw text at x,y
        this.text(txt, x, y);
    }
})(jsPDF.API);

